.loss {
  background-color: #f7d5d5;
  white-space: nowrap;
}

.gain {
  background-color: #dbf1da;
  white-space: nowrap;
}

.neither {
  white-space: nowrap;
}

.dashboard-table {
  max-width: 95%;
}