.Dashboard-header {
  background-color: #FFF;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.dashboard-buttons {
  margin: 10px !important;
}

.position-active {
  color: black
}

.position-inactive {
  color: rgb(199, 195, 195)
}
